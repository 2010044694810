.intro-header30 {
  width: 100%;
  height: 785px;
  display: flex;
  opacity: 1;
  padding: var(--dl-space-space-unit);
  background-size: cover;
  background-image: url("/cars/urus-1-1500w.jpg");
}
 
.intro-container {
  width: auto;
  max-width: auto;
  border-radius: var(--dl-radius-radius-radius2);
  animation-name: pulse;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 10;
  animation-timing-function: ease;
}
 
.intro-text1 {
  gap: var(--dl-space-space-unit);
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  width: auto;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  text-align: center;
  align-items: flex-start;
  font-family: "Noto Sans";
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: center;
  background-image: linear-gradient(to right, rgba(189, 195, 199, 0.8) 0%, rgba(44, 62, 80, 0.8) 80%),#000;;
}
 
.intro-text2 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  width: auto;
  height: auto;
  display: flex;
  text-align: center;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  justify-content: center;
  background-image: linear-gradient(to right, rgba(189, 195, 199, 0.8) 0%, rgba(44, 62, 80, 0.8) 80%);
}
 
.intro-text3 {
  display: inline-block;
}
 
.intro-text4 {
  color: #ffffff;
  display: inline-block;
}
 
.introroot-class-name {
  align-self: center;
}
 
@media(max-width: 1200px) {
  .intro-text1 {
    width: 100%;
    height: 76px;
  }
  .intro-text2 {
    width: 100%;
  }
}
 
@media(max-width: 991px) {
  .intro-header30 {
    height: 477px;
  }
  .intro-text1 {
    width: 100%;
    height: auto;
    font-size: 28px;
    text-align: center;
  }
  .intro-text2 {
    font-size: 14px;
    text-align: center;
  }
}
 
@media(max-width: 767px) {
  .intro-header30 {
    height: 371px;
  }
  .intro-text1 {
    width: 100%;
    height: auto;
    font-size: 25px;
  }
  .intro-text2 {
    font-size: 10px;
  }
}
 
@media(max-width: 479px) {
  .intro-header30 {
    height: 239px;
  }
  .intro-text1 {
    height: auto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }
  .intro-text2 {
    font-size: 8px;
  }
}
