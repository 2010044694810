.info-layout251 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.info-max-width {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.info-container1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
 
.info-container2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.info-image1 {
  width: 54px;
  height: 54px;
  display: flex;
  object-fit: cover;
  align-items: flex-start;
}
 
.info-text10 {
  display: flex;
  justify-content: center;
}
 
.info-text11 {
  text-align: center;
}
 
.info-container3 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-self: stretch;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.info-image2 {
  width: 54px;
  height: 54px;
  display: flex;
  object-fit: cover;
  align-items: flex-start;
}
 
.info-text12 {
  display: flex;
}
 
.info-text13 {
  text-align: center;
}
 
.info-container4 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.info-image3 {
  width: 54px;
  height: 54px;
  display: flex;
  overflow: visible;
  object-fit: cover;
  align-items: flex-start;
  aspect-ratio: auto;
}
 
.info-text15 {
  text-align: center;
}
 
.info-container5 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  background-color: var(--dl-color-theme-neutral-light);
}
 
.info-image4 {
  width: 54px;
  height: 54px;
  display: block;
  padding: 2px;
  object-fit: cover;
}
 
.info-text16 {
  text-align: center;
}
 
.info-text17 {
  display: flex;
  text-align: center;
}
 
.info-text18 {
  display: inline-block;
}
 
.info-text19 {
  display: inline-block;
}
 
.info-text20 {
  display: inline-block;
}
 
.info-text21 {
  display: inline-block;
}
 
.info-text22 {
  display: inline-block;
}
 
.info-text23 {
  display: inline-block;
}
 
.info-text24 {
  display: inline-block;
}
 
.info-text25 {
  display: inline-block;
}
 
.inforoot-class-name {
  position: static;
}
 
@media(max-width: 991px) {
  .info-layout251 {
    width: 100%;
    height: 100%;
    padding: var(--dl-space-space-fiveunits);
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .info-max-width {
    flex-direction: column;
  }
  .info-container1 {
    height: auto;
    position: static;
    align-self: center;
  }
  .info-container2 {
    gap: var(--dl-space-space-unit);
    width: auto;
    height: 325px;
    padding: var(--dl-space-space-unit);
    align-self: center;
    align-items: center;
    justify-content: flex-start;
  }
  .info-text10 {
    align-items: flex-start;
  }
  .info-container3 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }
  .info-image2 {
    padding-right: Infinitypx;
  }
  .info-container4 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }
  .info-container5 {
    gap: var(--dl-space-space-unit);
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
  }
  .info-text16 {
    display: flex;
  }
  .inforoot-class-name {
    width: auto;
  }
}
 
@media(max-width: 767px) {
  .info-container1 {
    flex-direction: column;
  }
  .info-container2 {
    width: 100%;
  }
  .info-container3 {
    width: 100%;
  }
  .info-container4 {
    width: 100%;
  }
  .info-container5 {
    width: 100%;
  }
}
