.contact3-contact20 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: ridge;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 3px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
 
.contact3-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.contact3-section-title {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.contact3-content1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.contact3-row {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
 
.contact3-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.contact3-contact-info1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.contact3-content3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
 
.contact3-text3 {
  align-self: stretch;
  text-align: center;
}
 
.contact3-email {
  text-align: center;
  font-weight: bold;
}
 
.contact3-content4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.contact3-contact-info2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.contact3-content5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
 
.contact3-text4 {
  align-self: stretch;
  text-align: center;
}
 
.contact3-phone {
  text-align: center;
  font-weight: bold;
}
 
.contact3-content6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 338px;
  height: 232px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.contact3-link1 {
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 8px 0;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  padding-left: 8px 0;
  flex-direction: row;
  justify-content: center;
}
 
.contact3-link2 {
  display: contents;
}
 
.contact3-icon5 {
  text-decoration: none;
}
 
.contact3-social-link2 {
  font-weight: 700;
  text-decoration: none;
}
 
.contact3-social-link1-title {
  font-size: 22px;
  text-align: center;
  text-transform: capitalize;
}
 
.contact3root-class-name {
  position: static;
}
 
@media(max-width: 1200px) {
  .contact3-link1 {
    padding: 0px;
  }
}
 
@media(max-width: 991px) {
  .contact3-row {
    padding: var(--dl-space-space-halfunit);
  }
  .contact3-content6 {
    width: 100%;
    height: auto;
  }
  .contact3-link1 {
    padding: 0px;
  }
  .contact3-social-link1-title {
    display: flex;
    font-size: 22px;
    align-items: stretch;
    flex-direction: column-reverse;
    text-transform: capitalize;
    justify-content: flex-start;
  }
}
 
@media(max-width: 767px) {
  .contact3-text1 {
    text-align: center;
  }
  .contact3-row {
    flex-direction: column;
  }
  .contact3-content2 {
    padding: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .contact3-content4 {
    padding: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .contact3-content6 {
    width: 100%;
    height: auto;
    padding: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .contact3-link1 {
    padding: 0px;
  }
  .contact3-social-link1-title {
    text-align: center;
  }
}
 
@media(max-width: 479px) {
  .contact3-text1 {
    align-self: center;
    text-align: center;
  }
  .contact3-text2 {
    font-size: 30px;
  }
  .contact3-row {
    align-items: stretch;
  }
  .contact3-content2 {
    padding: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-theme-neutral-dark);
    border-style: solid;
    border-width: 1px;
  }
  .contact3-icon1 {
    width: var(--dl-size-size-small);
  }
  .contact3-content4 {
    padding: var(--dl-space-space-halfunit);
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .contact3-content6 {
    width: auto;
    height: auto;
    padding: var(--dl-space-space-halfunit);
    align-items: center;
    border-color: var(--dl-color-theme-neutral-dark);
    border-width: 1px;
  }
  .contact3-link1 {
    width: auto;
    height: auto;
    padding: 0px;
  }
}
