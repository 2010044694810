.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: radial-gradient(circle at center, rgb(189, 195, 199) 0.00%,rgba(44, 62, 80, 0.04) 100.00%);
}
 
.home-text10 {
  display: inline-block;
}
 
.home-text11 {
  color: #ffffff;
  display: inline-block;
}
 
.home-text12 {
  display: inline-block;
}
 
.home-text13 {
  display: inline-block;
}
 
.home-text14 {
  display: inline-block;
}
 
.home-text15 {
  display: inline-block;
}
 
.home-text16 {
  display: inline-block;
}
 
.home-text17 {
  display: inline-block;
}
 
.home-text18 {
  display: inline-block;
}
 
.home-text19 {
  display: inline-block;
}
 
@media(max-width: 479px) {
  .home-text12 {
    font-size: 30px;
  }
  .home-text13 {
    font-size: 30px;
  }
  .home-text14 {
    font-size: 30px;
  }
}
