.stoc-layout251 {
  width: 100%;
  height: auto;
  display: block;
  opacity: 1;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: ridge;
  border-width: 1px;
  background-size: cover;
  background-image: url("/cars/stoc_background-1500w.jpg");
  border-top-width: 3px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
}
 
.stoc-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
 
.stoc-column {
  gap: var(--dl-space-space-halfunit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  align-items: flex-start;
  flex-shrink: 0;
}
 
.stoc-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  align-items: flex-start;
}
 
.stoc-button {
  width: auto;
  height: auto;
  align-self: flex-start;
  align-items: flex-start;
  border-color: var(--dl-color-theme-secondary1);
  flex-direction: column-reverse;
  background-color: #2f42ce;
}
 
.stoc-action1 {
  text-align: center;
  text-decoration: underline;
}
 
.stocroot-class-name {
  margin: var(--dl-space-space-unit);
  overflow: hidden;
  background-color: #D9D9D9;
}
 
@media(max-width: 991px) {
  .stoc-section-title {
    align-items: flex-start;
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .stoc-column {
    width: 100%;
  }
  .stoc-actions {
    flex-wrap: wrap;
  }
}
 
@media(max-width: 479px) {
  .stoc-actions {
    width: 100%;
    flex-direction: column;
  }
  .stoc-button {
    width: auto;
    align-self: center;
  }
}
