.logo-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
 
.logo-container2 {
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  justify-content: center;
}
 
.logo-image1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: ridge;
  border-width: 1px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
}
 

 
@media(max-width: 1200px) {
  .logo-container2 {
    justify-content: center;
  }
}
