.partener-layout251 {
  width: 100%;
  height: auto;
  display: block;
  opacity: 1;
  padding: var(--dl-space-space-unit);
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: ridge;
  border-width: 1px;
  border-top-width: 3px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
}
 
.partener-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
 
.partener-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: center;
  align-items: flex-start;
}
 
.partener-button {
  fill: transparent;
  color: transparent;
  width: auto;
  height: auto;
  align-self: flex-start;
  align-items: flex-start;
  border-width: 0px;
  flex-direction: column-reverse;
  justify-content: center;
  background-color: transparent;
}
 
.partener-action1 {
  color: #000000;
  align-self: center;
  text-align: center;
  text-decoration: underline;
}
 

 
@media(max-width: 767px) {
  .partener-actions {
    flex-wrap: wrap;
  }
}
 
@media(max-width: 479px) {
  .partener-layout251 {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: visible;
    background-image: linear-gradient(to right, rgba(189, 195, 199, 0.8) 0%, rgba(44, 62, 80, 0.8) 80%);
  }
  .partener-actions {
    width: 100%;
    flex-direction: column;
  }
  .partener-button {
    width: auto;
    align-self: flex-end;
  }
  .partenerroot-class-name {
    width: 100%;
    height: 100%;
    align-self: center;
  }
}
